import axios from "axios";
import { secureAPICall } from "../secureAPI/secureAPIGraph";

const URL = process.env.REACT_APP_BACKEND;

const API_BASE_URL = `${URL}mis-reports`;

export const fetchDistinctValues = async (
  param = "",
  value = "",
  reportName = "",
  activeTab = ""
) => {
  try {
    let params = {};

    // Add reportName and reportType (activeTab) to the params
    if (reportName) {
      params.report_name = reportName;
    }
    if (activeTab) {
      params.report_type = activeTab; // report_type will be "daily" or "monthly"
    }

    // Handle the `mmm` array separately
    if (param === "mmm" && Array.isArray(value)) {
      // Manually construct query parameters for multiple `mmm`
      value.forEach((month) => {
        if (!params[param]) {
          params[param] = [];
        }
        params[param].push(month); // Add each month to the array
      });
    } else if (param && value) {
      // For other single parameters, add them to `params`
      params[param] = value;
    }

    // Manually build the query string
    const queryString = Object.keys(params)
      .map((key) => {
        if (Array.isArray(params[key])) {
          // For array parameters, convert them to the correct format
          return params[key]
            .map(
              (val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
            )
            .join("&");
        }
        // For non-array params, return the key-value pair
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      .join("&");

    const response = await secureAPICall(
      `${API_BASE_URL}/distinct/?${queryString}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching filter values:", error);
    throw error;
  }
};

// export const fetchReportData = async (filters) => {
//   try {
//     // Default values for page and page_size if not provided
//     const page = filters.page || 1;
//     const pageSize = filters.page_size || 1000;

//     // Map cycle_date values to separate parameters joined by '&'
//     const cycleDateParams = filters.cycle_date
//       .map((date) => `cycle_date=${encodeURIComponent(date)}`)
//       .join("&");

//     // Map mmm values to separate parameters joined by '&'
//     const mmmParams = filters.mmm
//       .map((month) => `mmm=${encodeURIComponent(month)}`)
//       .join("&");

//     // // Construct the query string with properly formatted parameters
//     // const queryString = `?bu_name=${encodeURIComponent(
//     //   filters.bu_name
//     // )}&fy_year=${encodeURIComponent(
//     //   filters.fy_year
//     // )}&${mmmParams}&${cycleDateParams}&page=1&page_size=100`;

//     // Construct the query string with properly formatted parameters
//     const queryString = `?bu_name=${encodeURIComponent(
//       filters.bu_name
//     )}&fy_year=${encodeURIComponent(
//       filters.fy_year
//     )}&${mmmParams}&${cycleDateParams}&page=${page}&page_size=${pageSize}`;

//     const url = `${API_BASE_URL}/report/${queryString}`;

//     console.log("Final URL:", url); // Log the final URL for debugging

//     // Make the GET request with the constructed URL
//     const response = await axios.get(url);
//     console.log("Fetched response:", response); // Log full response

//     if (response.data) {
//       console.log("Response data check:", response.data);
//       return {
//         data: response.data.data || [], // Ensure it defaults to an empty array if undefined
//         totalCount: response.data.total_count || 0, // Ensure it defaults to 0 if undefined
//       };
//     } else {
//       console.error("Response has no data property.");
//       return { data: [], totalCount: 0 };
//     }
//   } catch (error) {
//     console.error("Error fetching the report:", error);
//     throw error;
//   }
// };

// v1
// export const fetchReportData = async (
//   filters,
//   reportName,
//   reportType,
//   fetchAll = false
// ) => {
//   try {
//     const page = filters.page || 1;
//     const pageSize = filters.page_size || 1000;

//     const cycleDateParams = filters.cycle_date
//       .map((date) => `cycle_date=${encodeURIComponent(date)}`)
//       .join("&");
//     const mmmParams = filters.mmm
//       .map((month) => `mmm=${encodeURIComponent(month)}`)
//       .join("&");

//     // const queryString = `?bu_name=${encodeURIComponent(
//     //   filters.bu_name
//     // )}&fy_year=${encodeURIComponent(
//     //   filters.fy_year
//     // )}&${mmmParams}&${cycleDateParams}${
//     //   fetchAll ? "&fetchAll=true" : `&page=${page}&page_size=${pageSize}`
//     // }`;

//     // const queryString = `?bu_name=${encodeURIComponent(
//     //   filters.bu_name
//     // )}&fy_year=${encodeURIComponent(
//     //   filters.fy_year
//     // )}&${mmmParams}&${cycleDateParams}&reportName=${encodeURIComponent(
//     //   reportName
//     // )}&reportType=${encodeURIComponent(reportType)}${
//     //   fetchAll ? "&fetchAll=true" : `&page=${page}&page_size=${pageSize}`
//     // }`;

//     // Construct the query string ensuring proper `&` between sections
//     const queryString = `?reportName=${encodeURIComponent(
//       reportName
//     )}&reportType=${encodeURIComponent(
//       reportType
//     )}&bu_name=${encodeURIComponent(
//       filters.bu_name
//     )}&fy_year=${encodeURIComponent(filters.fy_year)}${
//       mmmParams ? `&${mmmParams}` : ""
//     }${cycleDateParams ? `&${cycleDateParams}` : ""}${
//       fetchAll ? "&fetchAll=true" : `&page=${page}&page_size=${pageSize}`
//     }`;

//     const response = await axios.get(`${API_BASE_URL}/report/${queryString}`);
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching report data:", error);
//     throw error;
//   }
// };

export const fetchReportData = async (
  filters,
  reportName,
  reportType,
  fetchAll = false
) => {
  try {
    const page = filters.page || 1;
    const pageSize = filters.page_size || 1000;

    const cycleDateParams = filters.cycle_date
      .map((date) => `cycle_date=${encodeURIComponent(date)}`)
      .join("&");
    const mmmParams = filters.mmm
      .map((month) => `mmm=${encodeURIComponent(month)}`)
      .join("&");
    const deptNameParams = filters.dept_name
      ? filters.dept_name
          .map((dept) => `dept_name=${encodeURIComponent(dept)}`)
          .join("&")
      : "";

    // Construct the query string ensuring proper `&` between sections
    const queryString = `?reportName=${encodeURIComponent(
      reportName
    )}&reportType=${encodeURIComponent(
      reportType
    )}&bu_name=${encodeURIComponent(
      filters.bu_name
    )}&fy_year=${encodeURIComponent(filters.fy_year)}${
      mmmParams ? `&${mmmParams}` : ""
    }${cycleDateParams ? `&${cycleDateParams}` : ""}${
      deptNameParams ? `&${deptNameParams}` : ""
    }${fetchAll ? "&fetchAll=true" : `&page=${page}&page_size=${pageSize}`}`;

    const response = await axios.get(`${API_BASE_URL}/report/${queryString}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching report data:", error);
    throw error;
  }
};
