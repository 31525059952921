// export const msalConfig = {
//   auth: {
//     clientId: String(process.env.REACT_APP_CLIENT_ID),
//     authority: String(process.env.REACT_APP_AUTH_AUTHORITY),
//     redirectUri: String(process.env.REACT_APP_REDIRECT_URL),
//     postLogoutRedirectUri: String(
//       process.env.REACT_APP_POST_LOGOUT_REDIRECT_URL
//     ),
//   },
//   cache: {
//     cacheLocation: "localStorage", // This configures where your cache will be stored
//     storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//   },
//   //   system: {
//   //     loggerOptions: {
//   //       loggerCallback: (level, message, containsPii) => {
//   //         if (containsPii) {
//   //           return;
//   //         }
//   //         switch (level) {
//   //           case LogLevel.Error:
//   //             console.error(message);
//   //             return;
//   //           case LogLevel.Info:
//   //             console.info(message);
//   //             return;
//   //           case LogLevel.Verbose:
//   //             console.debug(message);
//   //             return;
//   //           case LogLevel.Warning:
//   //             console.warn(message);
//   //             return;
//   //           default:
//   //             return;
//   //         }
//   //       },
//   //     },
//   //   },
// };

// /**
//  * Scopes you add here will be prompted for user consent during sign-in.
//  * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
//  * For more information about OIDC scopes, visit:
//  * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
//  */

// export const loginRequest = {
//   scopes: ["openid", "profile", "User.Read"],
// };

// // const log = REACT_APP_CLIENT_ID
// console.log(
//   process.env.REACT_APP_CLIENT_ID,
//   process.env.REACT_APP_POST_LOGOUT_REDIRECT_URL
// );

// /**
//  * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
//  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
//  */
// export const graphConfig = {
//   graphMeEndpoint: String(process.env.REACT_APP_GRAPH_ME),
// };

export const msalConfig = {
  auth: {
    clientId: String(process.env.REACT_APP_CLIENT_ID),
    authority: String(process.env.REACT_APP_AUTH_AUTHORITY),
    redirectUri: String(process.env.REACT_APP_REDIRECT_URL),
    postLogoutRedirectUri: String(
      process.env.REACT_APP_POST_LOGOUT_REDIRECT_URL
    ),
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [
    "openid",
    "profile",
    "User.Read",
    "api://843d4b2b-f80e-4700-bbbf-51d2ba257628/api.read",
    // "https://analysis.windows.net/powerbi/api/Report.Read.All", // Added Power BI API scope
  ],
};

export const graphConfig = {
  graphMeEndpoint: String(process.env.REACT_APP_GRAPH_ME),
};

// For accessing Power BI, you'll need to configure the report endpoint, if necessary
export const powerBIConfig = {
  powerBiApiEndpoint: "https://api.powerbi.com/v1.0/myorg/reports",
};
