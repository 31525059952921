import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  LinearProgress,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify/Iconify";
//
// import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import { useLoaderContext } from "../../../context/LoaderContext";
import { useNavigate, useLocation } from "react-router-dom";
import useResponsive from "../../../hooks/useResponsive";
import { HeightLaunch } from "../../../animated/index";
// import KhilLogo from "../../../assets/iinsights-logo.png";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 78;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  backgroundColor: "transparent",
  backdropFilter: "blur(20px)",
  [theme.breakpoints.up("xxl")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("xxl")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const { isLoading } = useLoaderContext();
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useResponsive("up", "xxl");

  const goBack = () => {
    navigate(-1); // This will navigate one step back in the history.
  };

  return (
    <>
      <StyledRoot>
        <StyledToolbar>
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: "text.primary",
              display: { xxl: "none" },
            }}
          >
            {/* <Iconify icon="eva:menu-2-fill" /> */}
            <svg
              className="icon"
              width="100"
              height="95"
              viewBox="0 0 100 95"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M42.4897 45.8557L51.24 54.5699C51.521 54.8497 51.9755 54.8497 52.2565 54.5699L67.3505 39.538"
                stroke="#477BFF"
                stroke-width="6.2152"
                stroke-linecap="round"
              />
              <path
                d="M69.9404 47.048C69.9404 58.0608 61.0127 66.9885 49.9999 66.9885C38.9871 66.9885 30.0595 58.0608 30.0595 47.048C30.0595 36.0352 38.9871 27.1076 49.9999 27.1076C61.0127 27.1076 69.9404 36.0352 69.9404 47.048Z"
                stroke="url(#paint0_linear_209_1338)"
                stroke-width="6.2152"
              />
              <path
                d="M40.5735 48.0839C43.0305 48.0839 45.1313 46.1717 45.1313 43.6815C45.1313 41.1913 43.0305 39.279 40.5735 39.279C38.1166 39.279 36.0157 41.1913 36.0157 43.6815C36.0157 46.1717 38.1166 48.0839 40.5735 48.0839Z"
                fill="#477BFF"
                stroke="white"
                stroke-width="2.58967"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_209_1338"
                  x1="65.8613"
                  y1="39.3927"
                  x2="33.3655"
                  y2="74.454"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#477BFF" />
                  <stop offset="1" stop-color="#FFFBF7" />
                </linearGradient>
              </defs>
            </svg>

            {/* <Logo /> */}
          </IconButton>
          {/* <img
            src={KhilLogo}
            alt=""
            style={{ height: "8vh", width: "8vh", marginRight: "1rem" }}
          /> */}
          {/* Add the heading */}
          {/* <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center", color: "black" }}
          >
            Kamat Hotel India Ltd
          </Typography> */}
          {/* <Searchbar /> */}
          <Box sx={{ flexGrow: 1 }} />
          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            <NotificationsPopover />
            <AccountPopover />
          </Stack>
        </StyledToolbar>

        {/* Display the loading bar if loading */}
        {isLoading && (
          <HeightLaunch>
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </HeightLaunch>
        )}
        {/* Back button logic */}
        {location.pathname.startsWith("/dashboard/profile") &&
          location.pathname !== "/dashboard/profile" && (
            <Box
              sx={{
                position: "fixed",
                top: `${isDesktop ? "1.4rem" : "0.6rem"}`,
                left: `${isDesktop ? "0" : "4rem"}`,
                backdropFilter: "blur(20px)",
              }}
            >
              <Button
                size="large"
                color="primary"
                width="5rem"
                sx={{
                  backgroundColor: "transparent",
                  backdropFilter: "blur(5px)",
                }}
                onClick={goBack}
                startIcon={<Iconify icon={"eva:arrow-ios-back-fill"} />}
              >
                Back
              </Button>
            </Box>
          )}
      </StyledRoot>
    </>
  );
}
