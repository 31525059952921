import axios from "axios";

export default async function secureAPIGraphPicture(endpoint) {
  const fullEndpoint = `${endpoint}`;
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("Application-token")}`,
  };
  const config = {
    method: "GET",
    url: fullEndpoint,
    headers: headers,
    responseType: "blob",
  };

  try {
    const apiResponse = await axios(config);
    if (apiResponse.status === 404) {
      // Handle 404 error (Not Found)
      return {
        successFlag: false,
        message: "The requested resource was not found.",
      };
    }
    const imageblob = new Blob([apiResponse.data], { type: "image/jpeg" }); // Adjust the 'type' based on the image format
    const imageUrl = URL.createObjectURL(imageblob);
    return {
      successFlag: true,
      imageUrl: imageUrl,
    };
  } catch (error) {
    return {
      successFlag: false,
      message:
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred. Please try again.",
    };
  }
}
