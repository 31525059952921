import React from "react";
import { FilterProvider } from "src/context/FilterContext";
import GlobalFilters from "src/components/MIS/FilterDropdowns/GlobalFilters";
import ReportViewer from "src/components/MIS/ReportViewer/ReportViewer";

const ReportWrapper = ({ reportName }) => {
  return (
    <div
      style={{
        background: "#F5F6FA",
        padding: "24px 32px 40px 32px", // Adjusted padding
        flexDirection: "column", // Flexbox direction set to column
        display: "flex", // Enable Flexbox
        // alignItems: "flex-start", // Align items to the start (left)
        gap: "0px", // Spacing between elements
        flex: "1 0 0", // Ensure it grows and doesn't shrink
        alignSelf: "stretch", // Stretch to fill available space
        borderRadius: "8px", // Border radius for rounded corners
      }}
    >
      <FilterProvider reportName={reportName}>
        <GlobalFilters />
        <ReportViewer />
      </FilterProvider>
    </div>
  );
};

export default ReportWrapper;
