import axios from "axios";

export default async function secureAPIGraph(endpoint) {
  const fullEndpoint = `${endpoint}`;
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("Application-token")}`,
    "Content-Type": "application/json",
  };
  const config = {
    method: "GET",
    url: fullEndpoint,
    headers: headers,
  };

  try {
    const apiResponse = await axios(config);
    if (apiResponse.status === 404) {
      // Handle 404 error (Not Found)
      return {
        successFlag: false,
        message: "The requested resource was not found.",
      };
    }
    if (apiResponse.status === 200) {
      return {
        successFlag: true,
        message: apiResponse.data.message,
        data: apiResponse.data,
      };
    } else {
      return {
        successFlag: true,
        message: apiResponse.data.message || "An error occurred",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      successFlag: false,
      message: error.message || "Please try again",
    };
  }
}

export const secureAPICall = async (endpoint, method = "GET", data = null) => {
  const token = localStorage.getItem("Application-token");
  console.log(token);

  if (!token) {
    throw new Error("No token available. Please log in.");
  }

  const config = {
    method,
    url: endpoint,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };

  try {
    const response = await axios(config);
    return { success: true, data: response.data };
  } catch (error) {
    console.error("API Error:", error.response || error);
    return { success: false, error: error.response?.data || error.message };
  }
};
