import React, { useContext, useState, useMemo, useEffect } from "react";
import { Tabs, Tab, CircularProgress, Typography, Box } from "@mui/material";
// import axios from "axios";
import { FilterContext } from "../../../context/FilterContext";
import { fetchReportData } from "../../../api/reportAPI/index";
import "./ReportViewer.css"; // Import the CSS for styles
import DynamicAggregationTable from "./v3_MIS";
import NoReportDataImg from "./nodata.svg";
import reportConfigurations from "./ReportConfig";

const ReportViewer = () => {
  const { filters, reportName, activeTab, clearFilters } =
    useContext(FilterContext); // Get filters from context
  const [reportData, setReportData] = useState([]); // Main report data
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [currentDepartment, setCurrentDepartment] = useState(0);

  // Clear all filters
  const handleClearFilters = () => {
    clearFilters();
  };

  // Determine the report type based on activeTab or other logic
  const reportType = activeTab === "daily" ? "daily" : "monthly";

  // Reset `reportData` when the "clearReportData" event is dispatched
  useEffect(() => {
    const handleClearData = () => setReportData([]);
    window.addEventListener("clearReportData", handleClearData);

    return () => {
      window.removeEventListener("clearReportData", handleClearData);
    };
  }, []);

  const fetchAllData = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchReportData(filters, reportName, reportType, true); // Fetch all rows
      setReportData(data.data); // Store the full dataset
      // setInitialLoadComplete(true); // Mark initial load as complete
    } catch (err) {
      setError("Failed to fetch all data");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // const exportPivotTable = async () => {
  //   if (!reportData || reportData.length === 0) {
  //     alert("No data available for export!");
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(
  //       "http://127.0.0.1:8000/mis-reports/export-pivot/",
  //       { raw_data: reportData },
  //       { responseType: "blob" } // Ensure response is treated as a file
  //     );

  //     // Create a download link for the exported file
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "pivot_table.xlsx");
  //     document.body.appendChild(link);
  //     link.click();
  //     link.remove();
  //   } catch (error) {
  //     console.error("Error exporting pivot table:", error);
  //     alert("Failed to export pivot table.");
  //   }
  // };

  // Get the current configuration for the report type
  const currentConfig = reportConfigurations[reportName];

  // Function to group data by department
  const groupedData = useMemo(() => {
    if (!reportData.length || reportName !== "departmentalGroup") return {};
    return groupDataByDepartment(reportData, "DEPT_NAME");
  }, [reportData, reportName]);

  const departmentNames = useMemo(
    () => Object.keys(groupedData),
    [groupedData]
  );

  const handleTabChange = (event, newValue) => {
    setCurrentDepartment(newValue);
  };

  if (!currentConfig) {
    return <div>Invalid report type.</div>;
  }

  const selectedDepartmentData =
    departmentNames.length > 0
      ? groupedData[departmentNames[currentDepartment]]
      : [];

  // Adjust the column group based on activeTab
  const adjustedConfig = {
    ...currentConfig,
    columnGroup:
      activeTab === "monthly"
        ? ["FY_YEAR", "MMM"]
        : ["FY_YEAR", "MMM", "CYCLE_DATE"], // Use default for daily
  };

  return (
    <div className="report-viewer">
      {/* Fetch Report Button */}
      <div className="buttons">
        <div className="button-left">
          <h3>Refine Your Search</h3>
        </div>
        <div className="button-right">
          <button
            className="clear-filters-btn"
            onClick={handleClearFilters}
            style={{
              borderRadius: "100px",
              border: "2px solid #EAECF0",
              display: "flex",
              padding: "12px 16px",
              justifyContent: "center",
              alignItems: "center",
              color: "#9B9BA1",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
              fontWeight: 500,
              background: "white",
              lineHeight: "normal",
              gap: "10px",
            }}
          >
            Clear Filters
          </button>
          <button
            className="fetch-report-btn"
            onClick={fetchAllData}
            style={{
              borderRadius: "100px",
              border: "2px solid #7BA1FF",
              display: "flex",
              padding: "12px 16px",
              justifyContent: "center",
              alignItems: "center",
              color: "#FFFFFF",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
              fontWeight: 500,
              background: "white",
              lineHeight: "normal",
              gap: "10px",
              background:
                "var(--vfdvdvd, linear-gradient(90deg, #477BFF 0%, #7FA1FD 100%))",
            }}
          >
            Fetch Report
          </button>
        </div>
      </div>

      {/* Export Pivot Button */}
      {/* <button
        className="export-pivot-btn"
        onClick={exportPivotTable}
        disabled={!reportData.length}
      >
        Export Pivot Table
      </button> */}

      {/* Loading Spinner */}
      {loading && (
        <div className="loading-spinner">
          <CircularProgress />
          <Typography variant="body1" sx={{ marginLeft: "1rem" }}>
            Loading report data...
          </Typography>
        </div>
      )}

      {/* Error Message */}
      {error && <div className="error-message">{error}</div>}

      {/* Message when no report data is available */}
      {!loading && !error && reportData.length === 0 && (
        <div className="noreport-container">
          <div className="noreport">
            <div className="noreport-header">
              <h1>No Data Available.</h1>
              <h3>
                We couldn’t find any reports matching the selected filters. Try
                adjusting your filters or selecting a broader range to view
                data.
              </h3>
            </div>
            <img src={NoReportDataImg} alt="Loading..." />
          </div>
        </div>
      )}

      {/* Conditionally Render Content Based on `reportName` */}
      {!loading && !error && reportData.length > 0 && (
        <div className="report-container-main">
          {reportName === "departmentalGroup" ? (
            departmentNames.length > 0 && (
              <div className="report-container-div">
                {/* Tabs for each department */}
                {/* Scrollable Tabs for each department */}
                <Tabs
                  value={currentDepartment}
                  onChange={handleTabChange}
                  aria-label="Department Tabs"
                  variant="scrollable" // Enable scrollable tabs
                  scrollButtons="auto" // Show scroll buttons when needed
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      justifyContent: "space-between", // Distribute tabs evenly
                    },
                    "& .MuiTab-root": {
                      textTransform: "none", // Keep tab labels in normal case
                      minWidth: "120px", // Set a minimum width for each tab
                      fontSize: "1rem", // Adjust font size for better visibility
                      padding: "10px 16px", // Add padding for better click area
                    },
                  }}
                >
                  {departmentNames.map((deptName, index) => (
                    <Tab
                      label={deptName}
                      key={deptName}
                      sx={{
                        backgroundColor:
                          index === currentDepartment ? "#e0e0e0" : "inherit", // Highlight active tab
                        borderRadius: "4px", // Add rounded corners
                        "&:hover": {
                          backgroundColor: "#f5f5f5", // Change color on hover
                        },
                      }}
                    />
                  ))}
                </Tabs>

                {/* Render DynamicAggregationTable for the selected department */}
                <DynamicAggregationTable
                  reportData={selectedDepartmentData}
                  rowGroup={adjustedConfig.rowGroup}
                  rowGroupSortKeys={adjustedConfig.rowGroupSortKeys}
                  aggregatedValue={adjustedConfig.aggregatedValue}
                  aggregationType={adjustedConfig.aggregationType}
                  title={departmentNames[currentDepartment]} // Department Name as Title
                  subTitle={adjustedConfig.subTitle}
                  columnGroup={adjustedConfig.columnGroup}
                  reportName={reportName}
                />
              </div>
            )
          ) : (
            <DynamicAggregationTable
              reportData={reportData}
              rowGroup={currentConfig.rowGroup}
              rowGroupSortKeys={currentConfig.rowGroupSortKeys}
              aggregatedValue={currentConfig.aggregatedValue}
              aggregationType={currentConfig.aggregationType}
              title={reportData?.[0]?.BU_NAME || "Unknown Business Unit"}
              subTitle={currentConfig.subTitle}
              columnGroup={currentConfig.columnGroup}
              reportName={reportName}
            />
          )}
        </div>
      )}
    </div>
  );
};

// Group data by the specified field
const groupDataByDepartment = (data, departmentField) => {
  return data.reduce((acc, item) => {
    const deptName = item[departmentField];
    if (!acc[deptName]) acc[deptName] = [];
    acc[deptName].push(item);
    return acc;
  }, {});
};

export default ReportViewer;
